import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const teamAnimations = () => {
    const teamSections = gsap.utils.toArray('.team');

    if (teamSections.length > 0) {        
        teamSections.forEach((teamSection) => {
            const members = teamSection.querySelectorAll('.team__member');
            const teamTl = gsap.timeline({
                scrollTrigger: {
                    trigger: teamSection,
                    toggleActions: "play none play none",
                },
            });

            teamTl.from(members, {
                scale: 0,
                duration: 0.4,
                delay: 0.6,
                stagger: 0.25,
                rotate: '-4deg',
                transformOrigin: 'center',
                ease: 'back.out(1)'
            });
        });
    };
};

export default teamAnimations;

