import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const tagsAnimation = () => {
    const tags = gsap.utils.toArray('.tag');

    if (tags.length > 0) {   
        tags.forEach((tag) => {
            const tagTl = gsap.timeline({
                scrollTrigger: {
                    trigger: tag,
                    toggleActions: "play none play none",
                }
            });

            tagTl.from(tag, {
                opacity: 0,
                duration: 0.5,
                ease: 'circ.out'
            });
        })
    };
};

export default tagsAnimation;