import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const subtitlesAnimation = () => {
    const titles = gsap.utils.toArray('.title');
    const screenWidth = window.innerWidth;
    
    if(titles.length > 0 && screenWidth > 1280) {
        titles.forEach((title) => {
            const text = title.querySelector('.title__text');
            const underline = title.querySelector('.title__underline');
        
            const titleTl = gsap.timeline({
                scrollTrigger: {
                    trigger: title,
                }
            });
            
            titleTl.from(text, {
                opacity: 0,
                duration: 0.6,
                delay: 0.4,
                ease: 'circ.in'
            })
            .from(underline, {
                width: 0,
                opacity: 0,
                x: -120,
                duration: 0.8,
                ease: 'circ.out'
            });
        });
    }
}

export default subtitlesAnimation;
