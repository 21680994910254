import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const testimonialsAnimation = () => {
    const testimonial = document.querySelector('.testimonials');

    if (testimonial) {        
        const testimonialTitle = document.querySelector('.testimonials__title');
        
        const testimonialTl = gsap.timeline({
            scrollTrigger: {
                trigger: ('.testimonials'),
            }
        });
    
        const testimonialBtns = document.querySelectorAll('.testimonials__button');
        const testimonialCards = document.querySelectorAll('.card-testimonial');
        const testimonialQuotes = document.querySelectorAll('.testimonials__quote');
    
        testimonialTl.from(testimonialTitle, {
            opacity: 0,
            duration: 0.5,
            ease: 'circ.in'
        })
        .from(testimonialCards, {
            scale: 0,
            duration: 0.4,
            stagger: 0.2,
            rotate: '-4deg',
            ease: 'back.out(1)'
        })
        .from(testimonialQuotes, {
            scale: 0,
            duration: 0.2,
            stagger: 0.2,
            ease: 'back.out(1)'
        })
        .from(testimonialBtns, {
            opacity: 0,
            scale: 0,
            duration:0.2,
            stagger: 0.1,
            rotate: '-4deg',
            ease: 'back.out(1)'
        });
    };
};

export default testimonialsAnimation;
