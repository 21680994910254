import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const swiperTestimonials = () => {
    return new Swiper('.testimonials__slider', {
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        centeredSlides: true, 
        grid: {
            rows: 1,
        },
        navigation: {
            nextEl: '.testimonials__button--next',
            prevEl: '.testimonials__button--prev',
        },
        pagination: {
            el: '.testimonials__pagination',
            type: 'bullets',
            clickable: true, 
        },
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
                centeredSlides: true,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 40,
            }
        }
    });
}


export default swiperTestimonials;
