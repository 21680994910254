import { gsap } from "gsap";

const pageEnter = () => {    
    gsap.to('.transition', {
        duration: 0.7,
        ease: "power1.in",
        transformOrigin: 'bottom right',
        scaleY: 0,
    });
};

export default pageEnter;