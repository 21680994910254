import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.config({
    nullTargetWarn: false,
});

function cardsEnter(array, cardClass, direction){
    const screenWidth = window.innerWidth;
    
    array.forEach((collection) => {
        const cards = collection.querySelectorAll(cardClass);

        if (screenWidth > 1280) {  
            const cardsTl = gsap.timeline({
                scrollTrigger: {
                    trigger: collection,
                    toggleActions: "play none play none",
                }
            });

            if (direction == 'to') {
                cardsTl.to(cards, {
                    scale: 1,
                    opacity: 1,
                    rotate: '0deg',
                    delay: 0.6,
                    duration: 0.5,
                    stagger: 0.2,
                    ease: 'back.out(1)'
                });
            } else {
                cardsTl.from(cards, {
                    scale: 0,
                    opacity: 0,
                    rotate: '-4deg',
                    delay: 0.6,
                    duration: 0.5,
                    stagger: 0.2,
                    ease: 'back.out(1)'
                });
            }
            
        } else {
            cards.forEach((card) => {
                const cardsTl = gsap.timeline({});
        
                if (direction == 'to') {
                    cardsTl.to(card, {
                        scale: 1,
                        opacity: 1,
                        rotate: '0deg',
                    });
                }
            });
        };
    });
};

const cardsEnterAnimation = () => {
    const cardsGridCollections = gsap.utils.toArray('.cards--grid');
    if (cardsGridCollections.length > 0) {        
        cardsEnter(cardsGridCollections, '.card');
    };

    const cardsRowollections = gsap.utils.toArray('.cards--row');
    if (cardsRowollections.length > 0) {        
        cardsEnter(cardsRowollections, '.card');
    };
    
    const cardsCasesCollections = gsap.utils.toArray('.cards--cases');
    if (cardsCasesCollections.length > 0) {     
        cardsEnter(cardsCasesCollections, '.card-case', 'to');
    };

    const cardsBlogsCollections = gsap.utils.toArray('.cards--blog');
    if (cardsBlogsCollections.length > 0) {
        cardsEnter(cardsBlogsCollections, '.card--blog')
    }
};

export default cardsEnterAnimation;