import { gsap } from "gsap";

const pageLeave = () => {
    gsap.to('.transition', {
        duration: 0.7,
        ease: "power1.out",
        transformOrigin: 'top right',
        scaleY: 1,
    });
};

export default pageLeave;