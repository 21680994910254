import Swiper, { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

const swiperCustomers = () => {
    return new Swiper('.customers__slider', {
        modules: [Autoplay],
        slidesPerView: 3,
        spaceBetween: 0,
        loop: true,
        grid: {
            rows: 1,
        },
        centeredSlides: true,
        autoplay: {
            delay: 1500,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 5,
            }
        }
    })
} 
    

export default swiperCustomers;
