import { gsap } from "gsap";

const buttonAnimation = () => {
    const buttons = gsap.utils.toArray('.button');
    const screenWidth = window.innerWidth;

    if ( buttons.length > 0 && screenWidth > 1280) {   
        buttons.forEach((button) => {
            button.addEventListener('mouseenter', () => {
                gsap.to(button, {
                    scale: 1.09,
                    rotate: '-4deg',
                    duration: 0.2,
                    ease: 'circ.out'
                });
            });
            
            button.addEventListener('mouseleave', () => {
                gsap.to(button, {
                    scale: 1,
                    rotate: '0deg',
                    ease: 'circ.out'
                });
            });
        });
    };
}

export default buttonAnimation;