import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const textImageAnimations = () => {
    const textImageSections = gsap.utils.toArray('.text-image');

    if (textImageSections.length > 0) {        
        textImageSections.forEach((textImageSection) => {
            const image = textImageSection.querySelector('.text-image__box');
            const text = textImageSection.querySelector('.text-image__content');

            const sectionTl = gsap.timeline()

            sectionTl.from(image, {
                scale: 0,
                duration: 0.6,
                delay: 0.4,
                rotate: '-4deg',
                transformOrigin: 'center',
                ease: 'back.out(1)'
            })
            .from(text, {
                opacity: 0,
                duration: 0.5,
                ease: "circ.in"
            })
        });
    };
};

export default textImageAnimations; 

