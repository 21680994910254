import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const filtersEnterAnimation = () => {
    const filter = document.querySelector('.filter');
    const screenWidth = window.innerWidth;

    if (filter && screenWidth > 1280 ) {  
        const filtersTl = gsap.timeline({
            scrollTrigger: {
                trigger: filter,
                toggleActions: "play play play none",
            }
        });

        const buttons = filter.querySelectorAll('.filter__service ');

        filtersTl.from(buttons, {
            scale: 0,
            opacity: 0,
            delay: 0.6,
            duration: 0.5,
            stagger: 0.2,
            ease: 'back.out(1)'
        });
    };
};

export default filtersEnterAnimation;