import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const swiperImages = () => {
    return new Swiper('.image-slider__slider', {
        modules: [Navigation, Autoplay, Pagination],
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        grid: {
            rows: 1,
        },
        navigation: {
            nextEl: '.image-slider__button--next',
            prevEl: '.image-slider__button--prev',
        },
        pagination: {
            el: '.swiper__pagination',
            type: 'bullets',
            clickable: true, 
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 40,
            }
        }
    })
} 
    

export default swiperImages;