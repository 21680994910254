import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const faqsAnimations = () => {
    const faqsSections = document.querySelectorAll('.faqs');
    const screenWidth = window.innerWidth;
    
    if ( faqsSections.length > 0) {   
        faqsSections.forEach((faqsSection) => {
            const questions = faqsSection.querySelectorAll('.faqs__row');

            if (screenWidth > 1280) {
                questions.forEach((question) => {
                    const faqsTl = gsap.timeline({
                        scrollTrigger: {
                            trigger: question,
                            toggleActions: "play none play none",
                        }
                    })

                    faqsTl.from(question, {
                        scaleX: 0,
                        duration: 0.6,
                        delay: 0.6,
                        transformOrigin: 'center',
                        rotate: '-4deg',
                        transformOrigin: 'center',
                        ease: 'back.out(1)'
                    });
                });
            };
        });
    };
};

export default faqsAnimations;

