import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const filtersAnimation = () => {
    const filter = document.querySelector('.filter');
    const screenWidth = window.innerWidth;

    const filtersServices = gsap.utils.toArray('.filter__service');
    const filtersSubservices = gsap.utils.toArray('.filter__subservice');
    
    if ( filtersServices && screenWidth > 1280) {  
        filtersServices.forEach((filter) => {
            filter.addEventListener('mouseenter', () => {
                gsap.to(filter, {
                    scale: 1.09,
                    rotate: '-4deg',
                    duration: 0.2,
                    ease: 'circ.out'
                })
            });
            
            filter.addEventListener('mouseleave', () => {
                gsap.to(filter, {
                    scale: 1,
                    rotate: '0deg',
                    ease: 'circ.out'
                })
            })
        });
    };

    if ( filtersSubservices && screenWidth > 1280) {  
        filtersSubservices.forEach((filter) => {
            filter.addEventListener('mouseenter', () => {
                gsap.to(filter, {
                    scale: 1.09,
                    duration: 0.2,
                })
            });
            
            filter.addEventListener('mouseleave', () => {
                gsap.to(filter, {
                    scale: 1,
                })
            })
        });
    };
};

export default filtersAnimation;