import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const roadmapAnimation = () => {
    const roadmaps = gsap.utils.toArray('.roadmap');

    if (roadmaps.length > 0) {        
        roadmaps.forEach((roadmap) => {
            const road = roadmap.querySelector('.roadmap__line');
            const title = roadmap.querySelector('.roadmap__title');
        
            const roadRowTl = gsap.timeline({
                scrollTrigger: {
                    trigger: road,
                    start: "top center",
                    end: "+=150px center",
                    scrub: true,
                }
            });
        
            roadRowTl.from(title, {
                opacity: 0
            })
            .from(road, {
                scaleY: 0,
                transformOrigin: "top",
                ease: "circ.in"
            })
        })
        
        const roadRows = gsap.utils.toArray('.roadmap__row');
        
        roadRows.forEach((row) => {
            const card = row.querySelector('.card--roadmap');
            const dot = row.querySelector('.roadmap__dot');
        
            const roadRowTl = gsap.timeline({
                scrollTrigger: {
                    trigger: card,
                    start: "top bottom",
                    end: "bottom center",
                    scrub: true
                }
            });
        
            roadRowTl
            .from(card, {
                scale: 0,
                delay: 0.6,
                duration: 0.6,
                ease: 'circ.out'
            })
            .from(dot, {
                scale: 0,
                ease: 'back.out(1)'
            })
            
        })
    };
};

export default roadmapAnimation;