import { gsap } from "gsap";

const caseAnimation = () => {
    const caseHeader = document.querySelector('.case__header');
    const caseTitle = document.querySelector('.case__title');
    const caseImage = document.querySelector('.case__image-box');
    const caseInfo= document.querySelectorAll('.case__info');

    if (caseHeader) {
        const caseTl = gsap.timeline();
        const screenWidth = window.innerWidth;

        if (screenWidth > 1280) {
            caseTl.from(caseTitle, {
                opacity: 0,
                duration: 0.6, 
                ease:"circ.in"
            })
            .from(caseInfo, {
                scale: 0,
                duration: 0.5,
                rotate: '-4deg',
                stagger: 0.4,
                ease: 'back.out(1)'
            })
            .from(caseImage, {
                scale: 0,
                duration: 0.5,
                rotate: '-4deg',
                ease: 'back.out(1)'
            })
        };
    }
}

export default caseAnimation;