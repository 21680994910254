import { gsap } from "gsap";

const sloganAnimation = () => {
    const slogans = gsap.utils.toArray('.slogan');
    
    if (slogans.length > 0) {   
        slogans.forEach((slogan) => {
            gsap.from(slogan, {
                opacity: 0,
                delay: 0.4,
                duration: 0.6,      
                ease: 'circ.out'        
            })
        })
    };
}

export default sloganAnimation;