import { gsap } from "gsap";

const heroAnimation = () => {
    const hero = document.querySelector('.hero');

    if (hero) {
        const heroWaves = document.querySelector('.waves--hero');

        const waveOnes = document.querySelectorAll('.wave--hero1');
        const waveTwos = document.querySelectorAll('.wave--hero2');

        if (heroWaves) {            
            waveOnes.forEach((wave) => {
                const waveTl = gsap.timeline({repeat: -1, yoyo: true});

                waveTl.to(wave, {
                    skewY: '1deg',
                    scaleY: 1.1,
                    duration: 3,
                    ease: 'elastic.easeOut'
                }, 'start')
            });

            waveTwos.forEach((wave) => {
                const waveTl = gsap.timeline({repeat: -1, yoyo: true});
                waveTl.to(wave, {
                    skewY: '-1deg',
                    scaleY: 0.9,
                    delay: 0.5,
                    duration: 3,
                    ease: 'elastic.easeOut'
                }, 'start');
            })
        };
    }

}

export default heroAnimation;