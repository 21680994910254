import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import anime from 'animejs/lib/anime.es.js';

gsap.registerPlugin(ScrollTrigger);

const sectionBlackAnimation = () => {
    const sectionBlack = gsap.utils.toArray('.section--wave');
    const screenWidth = window.innerWidth;

    if (sectionBlack.length > 0) {   
        
        if (screenWidth > 1280) {            
            sectionBlack.forEach((section) => {
                const sectionTl = gsap.timeline({
                    scrollTrigger: {
                        trigger: section
                    }
                });
        
                const sectionContainer = section.querySelector('.container');
        
                sectionTl.from(section, {
                        translateY: '10rem',
                        scaleY: 0,
                        opacity: 0,
                        duration: 0.8,
                        ease: 'back.out(1)'
                    })
                    .from(sectionContainer, {
                        opacity: 0,
                        duration: 0.4,
                        ease: 'circ.in'
                    })
            });
        }
    
        const wave1 = "M-4662-3833l1920.2-.121v72.13s-57.369,14.348-182.389,0-462.913-32.372-593.067-25.229-658.271,30.333-821.84,24.633S-4662-3786.22-4662-3786.22Z";
        const wave2 = "M-4662-3833l1920.2-.121v72.13s-276.326-47.542-426.97-43.644-432.171,36.5-562.326,43.644-442.3-37.944-605.869-43.644S-4662-3760.991-4662-3760.991Z";
        const wave3 = "M-4662-3833l1920.2-.121v72.13s-276.326-47.542-426.97-43.644-432.171,36.5-562.326,43.644-442.3-37.944-605.869-43.644S-4662-3760.991-4662-3760.991Z";
        const wave4 = "M-4662-3833l1920.2-.121v46.9s-36.788,31.8-199.587,26.193-641.541-33.337-771.7-26.193-412.455,31.893-576.023,26.193S-4662-3786.22-4662-3786.22Z";
    
        anime({
            targets: '.wave--section > path',
            easing: 'linear',
            duration: 7500,
            loop: true,
            d: [{
                    value: wave1
                },
                {
                    value: wave3
                },
                {
                    value: wave4
                },
                {
                    value: wave1
                },
            ],
        });
    };
}

export default sectionBlackAnimation;