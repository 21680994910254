import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const servicesAnimation = () => {
    const serviceBlock = gsap.utils.toArray('.service');
    const screenWidth = window.innerWidth;

    if (serviceBlock.length > 0 && screenWidth > 1280) {      
        serviceBlock.forEach((service) => {
            const serviceBlockTl = gsap.timeline({
                scrollTrigger: {
                    trigger: service,
                    toggleActions: "play none play none",
                }
            });
    
            const serviceLeft = service.querySelector('.service__left');
            const serviceRight = service.querySelector('.service__right');
    
            serviceBlockTl
            .from(serviceRight, {
                translateX: '100rem',
                duration: 0.8,
                delay: 0.4,
                ease: "circ.out"
            })
            .from(serviceLeft, {
                translateX: '-100rem',
                duration: 0.8,
                delay: 0.4,
                ease: "circ.out"
            }, 0)
            
        });
    };
};

export default servicesAnimation; 

