import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const formAnimations = () => {
    const formSections = gsap.utils.toArray('.form-section');

    if (formSections.length > 0) {        
        formSections.forEach((formSection) => {
            const content = formSection.querySelector('.form-section__content');
            const animation = formSection.querySelector('.form-section__animation')
            const form = formSection.querySelector('.fui-i');

            const sectionTl = gsap.timeline()

            if (content) {
                sectionTl.from(content, {
                    scale: 0,
                    duration: 0.4,
                    rotate: '-4deg',
                    ease: 'back.out(1)'
                });
            } 

            if (animation) {
                sectionTl.from(animation, {
                    scale: 0,
                    duration: 0.4,
                    rotate: '-4deg',
                    ease: 'back.out(1)'
                });
            }

            sectionTl.from(form, {
                scale: 0,
                duration: 0.4,
                rotate: '-4deg',
                ease: 'back.out(1)'
            })
        });
    };
};

export default formAnimations; 
