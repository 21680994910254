import { 
    pageEnter, 
    pageLeave, 
    buttonAnimation, 
    headerAnimation, 
    servicesAnimation ,
    cardsEnterAnimation,
    cardHoverAnimation,
    subtitlesAnimation,
    roadmapAnimation,
    sectionBlackAnimation,
    tagsAnimation,
    sloganAnimation,
    testimonialsAnimation,
    filtersAnimation,
    filtersEnterAnimation,
    caseAnimation,
    textImageAnimations,
    teamAnimations,
    faqsAnimations,
    formAnimations,
    heroAnimation,
} from './animations';

import {
    swiperTestimonials,
    swiperCustomers,
    swiperImages
} from './swiper';

import barba from '@barba/core';


//functions 
function delay(n) {
    n = n || 2000;
    return new Promise(done => {
        setTimeout(() => {
            done();
        }, n);
    })
};
//page transition
barba.init({
    transitions: [
    {
        async leave(){
        },
        async afterLeave(data) {
            const done = this.async();

            if (data.current.namespace === data.next.namespace) {
                done();
            } else {
                pageLeave();
                await delay(1000);
                window.scrollTo(0, 0);
                done();
            }
        },
        async enter(data){
            // headerAnimation();
            pageEnter();
            buttonAnimation();
            servicesAnimation();
            cardsEnterAnimation();
            cardHoverAnimation();
            subtitlesAnimation();
            roadmapAnimation();
            tagsAnimation();
            swiperTestimonials();
            swiperCustomers();
            swiperImages();
            sectionBlackAnimation();
            if (data.current.url.query == null) {
                sloganAnimation();
            }
            testimonialsAnimation();
            filtersEnterAnimation();
            filtersAnimation();
            caseAnimation();
            textImageAnimations();
            teamAnimations();
            faqsAnimations();
            formAnimations();
            heroAnimation();
        },
        async once (data){
            buttonAnimation();
            servicesAnimation();
            cardsEnterAnimation();
            cardHoverAnimation();
            subtitlesAnimation();
            roadmapAnimation();
            tagsAnimation();
            swiperTestimonials();
            swiperCustomers();
            swiperImages();
            sectionBlackAnimation();
            testimonialsAnimation();
            // headerAnimation();
            filtersAnimation();
            caseAnimation();
            textImageAnimations();
            teamAnimations();
            faqsAnimations();
            heroAnimation();
        }
    }
    ]
});
