import { gsap } from "gsap";

const screenWidth = window.innerWidth;

function cardsHover(array) {
    if (screenWidth > 1280) {
        array.forEach((card) => {
            card.addEventListener('mouseenter', () => {
                gsap.to(card, {
                    scale: 1.08,
                    rotate: '-4deg',
                    duration: 0.2,
                    ease: 'circ.out'
                });
            });
            
            card.addEventListener('mouseleave', () => {
                gsap.to(card, {
                    scale: 1,
                    rotate: '0deg',
                    ease: 'circ.out'
                });
            });
        });
    };
};

const cardHoverAnimation = () => {
    if (screenWidth > 1280) {        
        const cardLinks = gsap.utils.toArray('.card');
        if (cardLinks.length > 0) {            
            cardsHover(cardLinks);
        };
        
        const cardCaseLinks = gsap.utils.toArray('.card-case');
        if (cardCaseLinks.length > 0) {  
            cardsHover(cardCaseLinks);
        };
    }
};

export default cardHoverAnimation;